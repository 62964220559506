<template>
  <div class="component-radio-wraper">
    <span class="component-radio__label px-2" v-if="label">{{ label }}</span>
    <div
      class="element-wraper px-2 mt-1"
      v-for="(option, index) in options"
      :key="index"
    >
      <label :class="{ active: isElementActive(option) }">
        <input
          type="radio"
          name="radio-elements"
          v-bind:value="option"
          v-model="selectedValue"
        />
        <s></s>
        <span class="component-radio-element__label">{{
          getOptionsLabel(option)
        }}</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseRadio",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  emits: ["update:modelValue", "change"],
  inject: [],
  components: {},
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {},
  watch: {
    selectedValue(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("change", newValue);
    },
  },
  methods: {
    getOptionsLabel(options) {
      if (typeof options == "object") {
        return options.label;
      } else return options;
    },
    isElementActive(option) {
      return option == this.selectedValue ? true : false;
    },
  },
  created() {
    this.selectedValue = this.modelValue;
  },
};
</script>

<style lang="scss" scoped>
.component-radio__label {
font-size: 12px;
  font-weight: bold;
  color: rgb(120, 121, 121);
}
.component-radio-element__label {
  margin-left: 6px;
}
.element-wraper {
  cursor: pointer;
  &:hover {
    background-color: rgb(191, 194, 194);
  }
}
</style>
