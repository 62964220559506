import { sendRequest } from "@/helpers";
import axios from "axios";
const baseURL = process.env.VUE_APP_API_URL;

export default {
  async getUserInfo() {
    return await sendRequest(`/api/users/me`, "get", "");
  },
  async logout() {
    return await axios.get(
      `${baseURL}/api/auth/logout`, {}, { withCredentials: true }
    );   
  },
};
