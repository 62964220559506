<template>
  <button :type="type" :class="className" :disabled="disabled" style="position: relative;">
    <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span
    ><span class="v-btn__content" data-no-activator=""><slot></slot></span>
  </button>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
    className: {
      type: String,
      default: "v-btn",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    variant: {
      type: String,
      default: "default",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["mounted"],
  inject: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    sizeButton() {
      let size = "medium";
      switch (this.size) {
        case "S":
          size = "small";
          break;
        case "L":
          size = "large";
          break;
        default:
          size = "medium";
          break;
      }
      return size;
    },
  },
  methods: {},
  created() {},
  mounted() {
    this.$emit("mounted");
  },
};
</script>

<style scoped></style>
