<template>
  <div>
    <h2>{{ title }}</h2>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "BaseTab",
  props: {
    title:{
      type: String
    }
  },
  emits: ["update:modelValue", "change"],
  inject: [],
  components: {},
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    tabsList() {
      if (this.$slots.default) {
        let children = this.$slots.default();
        console.log(children);
        return [];
        // return this.$slots.default.filter((comp) => comp.componentOptions);
      }
      return [];
    },
  },
  watch: {},
  methods: {
    getOptionsLabel(options) {
      if (typeof options == "object") {
        return options.label;
      } else return options;
    },
    isElementActive(option) {
      return option == this.selectedValue ? true : false;
    },
  },
  created() {
    // this.selectedValue = this.modelValue;
  },
};
</script>

<style lang="scss" scoped></style>
