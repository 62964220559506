<template>
  <div
    class="v-slide-group v-tabs v-tabs--horizontal v-tabs--align-tabs-start v-tabs--density-default"
  >
    <div class="v-slide-group__container">
      <div class="v-slide-group__content" @mousemove="scrollMouse">
        <base-button
          v-for="(item, index) in availableTabsList"
          :key="index"
          variant="pure"
          className="v-btn v-theme--BLUE_THEME v-btn--density-default rounded-0 v-btn--size-default v-btn--variant-text v-tab"
          @click="changeActiveTab(item)"
          :class="{
            'v-tab-active': item === activeTab,
            'v-tab-disablead': isElementDisablead(item),
          }"
          >{{ item }}
        </base-button>
      </div>
    </div>
  </div>
  <hr
    class="v-divider v-theme--BLUE_THEME"
    aria-orientation="horizontal"
    role="separator"
  />
  <div
    class="v-card-text bg-grey100 mt-4 rounded-md"
    v-if="defaultSlotNotEmpty"
  >
    <!-- <temp-component>
      <template v-slot="data">
        {{ data }}
      </template>
    </temp-component> -->
    <slot></slot>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
// import TempComponent from "@/components/TempComponent.vue";

export default {
  name: "BaseTabs",
  props: {
    tabsList: {
      type: Array,
      required: false,
    },
    modelValue: {
      required: false,
    },
    instanceId: {
      type: [Number, String],
      required: false,
    },
  },
  emits: ["update:modelValue", "change"],
  inject: [],
  components: { BaseButton },
  data() {
    return {
      activeTab: null,
      selectedValue: null,
    };
  },
  computed: {
    availableTabsList() {
      if (
        this.tabsList &&
        Array.isArray(this.tabsList) &&
        this.tabsList.length > 0
      ) {
        return this.tabsList;
      } else if (this.$slots.default) {
        console.log("tyt");
        let children = this.$slots.default();
        let res = [];
        if (children && Array.isArray(children) && children.length > 0) {
          children.forEach((el) => {
            if (el?.props?.title) {
              res.push(el.props.title);
            }
          });
          return res;
        }
        console.log(children);
        return res;
        // return this.$slots.default.filter((comp) => comp.componentOptions);
      }

      return [];
    },
    defaultSlotNotEmpty() {
      return this.$slots.default ? true : false;
    },
  },
  watch: {
    selectedValue(newValue) {
      this.$emit("update:modelValue", newValue);
      this.$emit("change", newValue);
    },
  },
  methods: {
    changeActiveTab(tabName) {
      if (!this.isElementDisablead(tabName)) {
        this.activeTab = tabName;
        this.$emit("update:modelValue", tabName);
      }
      this.$emit("change", tabName);
    },
    getOptionsLabel(options) {
      if (typeof options == "object") {
        return options.label;
      } else return options;
    },
    isElementActive(option) {
      return option == this.selectedValue ? true : false;
    },
    isElementDisablead(option) {
      return option !== "Detail" && this.instanceId == undefined ? true : false;
    },
    scrollMouse(event) {
      let container = null;
      let parentW = document.getElementsByClassName("v-slide-group__container");
      let el = document.getElementsByClassName("v-slide-group__content");
      if (parentW && el) {
        container = el[0];
        if (parentW[0].offsetWidth >= container.offsetWidth) {
          return;
        }

        let width = document.documentElement.clientWidth;
        let mW = ((event.pageX * 100) / width).toFixed(3) - 15;
        container.style.transform = "translateX(" + -mW / 2 + "%)";
      }
    },
  },
  created() {
    this.activeTab = this.modelValue;
  },
};
</script>

<style lang="scss" scoped>
.v-tab-active {
  border-bottom: 3px solid rgb(var(--v-theme-secondary));
}
.v-tab-disablead {
  color: #999;
  background-color: #e2e1e1;
}
.component-radio__label {
  font-size: 12px;
  font-weight: bold;
  color: rgb(120, 121, 121);
}
.component-radio-element__label {
  margin-left: 6px;
}
.element-wraper {
  cursor: pointer;
  &:hover {
    background-color: rgb(191, 194, 194);
  }
}
</style>
