<template>
  <div class="base-multiselect">
    <div class="base-multiselect__wrapper">
      <div class="base-multiselect__container">
        <div
          style="align-items: center;"
          :class="[
            'base-multiselect__value d-flex h-100 w-100',
            { 'open-options': showItems },
            { 'has-value': multiselectValue?.length },
            { 'can-clear': canClear && multiselectValue?.length },
            {'disabled': disabled}
          ]"
          tabindex="0"
          @click.stop="openOption"
        >
          <!-- <span v-if="placeholder && !disabled" class="value__placeholder" >{{ placeholder }}</span> -->

          <div class="d-flex flex-row align-items-center h-100 w-100" style="padding: 0px 10px;">
            <div class="d-flex flex-row align-items-center h-100" style="flex: 1 0 auto;">
              <ul class="d-flex flex-row w-100" >
                
                <span v-if="placeholder && (multiselectValue.length < 1)" class="value__placeholder" >{{ placeholder }}</span>
                
                <li
                  class="value__item"
                  v-for="value in multiselectValue"
                  :key="value?.id || value"
                >
                  <slot name="value" :value="value">
                    <span :class="{ value__tag: chips }">
                      {{ labelProp ? value[labelProp] : value }}
                      <span class="item__separation" v-if="!chips && multiselectValue.length > 1">,</span>
                    </span>
                  </slot>
                  <button
                    v-if="chips"
                    type="button"
                    class="value__remove-btn"
                    @click.stop="removeItem(value)"
                  >
                    <span class="remove-btn__icon">+</span>
                  </button>
                </li>
              </ul>
              
              <button
                v-if="canClear && multiselectValue?.length && !disabled"
                type="button"
                class="value__clear-btn"
                @click.stop="clearValue"
              >
                <span class="clear-btn__icon">+</span>
              </button>
            </div>
            
            
            <span class="value__arrow d-flex h-100">
              <span class="arrow__icon"></span>
            </span>
          </div>
        </div>
        
        <div
          class="d-flex w-100"
          v-if="showItems"
          style="position: relative;"
        >
          <div class="base-multiselect__select">
            <div class="selection__container">
              <div class="selection__options">
                 <input              
                    v-if="search"
                    placeholder="Type to search"
                    name="searchQuery"
                    type="text"
                    autocomplete="off"
                    spellcheck="false"
                    tabindex="0"
                    class="search-input"
                    ref="input"
                    :value="searchQuery"
                    @click.stop
                    @input="updateSearchQuery($event)"
                    @keydown.arrow-down.exact.prevent="focusedFirstItem"
                  />
                <ul class="options__list" v-if="options?.length  >0">
                  <li
                    class="options__item"
                    tabindex="-1"
                    v-for="option in filtredOptions"
                    :key="option?.id || option"
                    @click.stop
                  >
                    <input
                      :checked="areOptionChecked(option)"
                      class="item__input"
                      :type="selectionType"
                      name="multiselectValue"
                      :id="option?.id || option"
                      :value="option"
                      v-model="multiselectValue"
                      @change="handleChange"
                      hidden
                    />
                    <label class="item__label" :for="option?.id || option">
                      <slot name="label" :label="option"
                        >{{ labelProp ? option[labelProp] : option }}
                      </slot>
                    </label>
                  </li>
                </ul>
                <!-- <h3 v-else>No item in options list</h3> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="base-multiselect__description" v-if="description">
        <p
          class="description__text"
          v-html="description?.text || description"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { isObject, hasProperty } from "@/helpers";
import EntityApi from "@/api/entityApi";
export default {
  name: "BaseMultiselect",
  model: {
    prop: "modelValue",
    event: "update:model-value",
  },
  props: {
    placeholder: {
      type: [String, Object],
      default: null,
    },
    items: {
      type: [String, Object, Array],
      default: null,
    },
    value: {
      type: [String, Number, Array, Object],
      // default: null,
    },
    modelValue: {
      type: [String, Number, Array, Object],
      // default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    labelProp: {
      type: String,      
    },
    valueProp: {
      type: String,     
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    description: {
      type: [String, Object],
      default: null,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchFunc:{
      type: Function
    }
  },
  emits: [
    "update:modelValue",
    "select",
    "removeItem",
    "openOption",
    "closeOption",
    "getOptions"
  ],
  data() {
    return {
      showItems: false,
      options:null,
      showLoader:false,
      searchQuery:"",
      debounce: null,
    };
  },
  watch: {
    //  searchQuery(value, oldValue) {
    //   if (value.length > 2 && !oldValue.includes(value)) {
    //     this.$emit("getOptions", value);
    //     this.loadOptions();
    //     this.showLoader = true;
    //   }
    // },
  },
  computed: {
    multiselectValue: {
      get() {
        if (this.modelValue || this.modelValue === null) {
          return this.correctModelValue;
        } else {
          return this.correctValue;
        }
      },
      set(value) {
        this.updateMultiselectValue(value);
      },
    },
    filtredOptions(){
      if (!this.search) {return this.options;}
      if (this.search && this.searchQuery?.length > 0) {
        return this.options.filter((el) => {
          const normalizedLabelProp = el?.[this.labelProp]?.toLowerCase() || "";
          const normalizedSearchQuery = this.searchQuery.toLowerCase();
          return normalizedLabelProp.includes(normalizedSearchQuery);
        })
      }
      else return this.options;

    },

    correctValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value;
        } else {
          return [this.value];
        }
      } else return [];
    },
    correctModelValue() {
      if (this.modelValue) {
        if (Array.isArray(this.modelValue)) {
          return this.modelValue;
        } else {
          return [this.modelValue];
        }
      } else return [];
    }, 
    selectionType() {
      if (this.multiple) {
        return "checkbox";
      } else {
        return "radio";
      }
    },
  },
  methods: {
    ititOptions(data){
      if (data) {
        if (Array.isArray(data)) {
          return data;
        }        
         else {
          return [data];
        }
      } else return [];
  
    },
    async openOption() {
      if (this.disabled) return;
      if (this.showItems) return this.closeOption();
      if(isObject(this.items) && hasProperty(this.items, "entity")  ){
        this.showLoader = true;
        let fieldOptions = await EntityApi.getEntityItems(this.items.entity);
        this.showLoader = false;
        this.options = fieldOptions;
        this.showItems = true;
          console.log("load options from server");
        }      
      else {    
        this.showItems = true;
      }      
      document.addEventListener("click", this.hideOption);
      this.$emit("openOption");
    },
    hideOption(e) {
      if (e.target.dataset.action === "open") {
        return;
      }
      this.closeOption();
    },
    closeOption() {
      this.showItems = false;
      document.removeEventListener("click", this.hideOption);
      this.$emit("closeOption");
    },

    areOptionChecked(option) {
      let checked = false;
      if (this.multiselectValue?.includes(option)) checked = true;
      return checked;
    },
    handleChange() {
      if (this.selectionType === "radio" || this.closeOnSelect)
        this.closeOption();
      if (this.modelValue || this.modelValue === null) return;
      // this.$emit("select", this.multiselectValue);
    },
    clearValue() {
      this.updateMultiselectValue(null);
    },
    removeItem(value) {
      const selectedValue = this.multiselectValue;
      const filteredValue = selectedValue.filter((item) => item !== value);
      if (filteredValue.length) {
        this.updateMultiselectValue(filteredValue);
      } else {
        this.updateMultiselectValue(null);
      }
      this.$emit("removeItem", value);
    },
    updateMultiselectValue(value) {
      if (this.modelValue || this.modelValue === null) {
        this.$emit("update:modelValue", value);
      } else {
        this.$emit("select", value);
      }
    },
     updateSearchQuery(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchQuery = e.target.value;
      }, this.debounceTime);
    },
    async loadOptions(){
      if(this.searchFunc){
        let res = await this.searchFunc(this.searchQuery);
        if (res) {
          this.options = res;
        }
      }
    }
  },
  created(){
    this.options = this.ititOptions(this.items);
  }
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.base-multiselect {
  font-size: var(--ms-font-size, 1rem);
  cursor: pointer;
  width: 100%;
  /* &:focus-within .base-multiselect__select {
    display: block;
  } */
}
.base-multiselect__wrapper {
  .base-multiselect__description {
    padding: 0.25rem;
    font-size: 0.8rem;
    line-height: 1;
  }
}
.base-multiselect__container {
  /*position: relative;*/

  .base-multiselect__value {
    /*position: relative;*/
    min-height: calc(
      2 * var(--bms-border-width, 1px) + var(--bms-font-size, 1rem) *
        var(--bms-line-height, 1.375) + 2 * var(--ms-py, 0.5rem)
    );
    width: 100%;
    border: var(--bms-border-width, 1px) solid var(--bms-border-color, #000);
    border-radius: var(--bms-border-radius, 4px);
    background-color: var(--bms-bg, #ffffff);

    &:hover {
      border-color: var(--bms-border-color-hover, #acacff);
    }
    &:focus-within {
      border-color: var(--bms-border-color-hover, #acacff);
    }

    &.open-options {
      .value__arrow {
        transform: rotate(180deg) translate(0%, 50%);
      }
    }
    &.has-value {
      .value__placeholder {
        top: 0;
        left: 0.5rem;
        transform: translateY(-50%);
        font-size: 0.7rem;
        line-height: 1;
        background-color: var(--bms-bg, #ffffff);
        border-radius: 4px;
        padding: 2px;
        color: inherit;
      }
    }
    &.can-clear {
      .value__container {
        padding-right: 3rem;
      }
    }

    .value__container {
      padding: 0.4rem;
      padding-right: 2rem;
      .value__list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .value__item {
        position: relative;
        display: flex;
        padding-bottom: 3px;

        &:last-child .item__separation {
          display: none;
        }
        .value__tag {
          padding: 4px 10px;
          overflow: hidden;
          background-color: var(--bms-tag-bg, #e8e9eb);
          border-radius: 0.8rem;
        }
        &:hover .value__remove-btn {
          display: flex;
        }
        .value__remove-btn {
          position: absolute;
          top: 0;
          left: 100%;
          transform: translate(-75%, -25%);
          width: 1rem;
          height: 1rem;
          color: #ffffff;
          background-color: #000000;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          /* display: flex; */
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          cursor: pointer;
          display: none;
          z-index: 1;

          &:hover {
            color: var(--bms-border-color-hover, #acacff);
          }
        }
        .remove-btn__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -55%) rotate(45deg);
        }
      }
      .value__item + .value__item {
        margin-left: 8px;
      }
    }
    .value__placeholder {
      color: var(--bms-placeholder-color, #c1c1c1);
      transition: transform 250ms ease-out, top 250ms ease-out,
        font-size 250ms ease-out;
    }
    /* &:focus-within .value__placeholder {
      top: 0;
      left: 0.5rem;
      transform: translateY(-50%);
      font-size: 0.7rem;
      line-height: 1;
      background-color: var(--bms-bg, #ffffff);
      border-radius: 4px;
      padding: 2px;
      color: var(--bms-border-color-hover, #acacff);
    } */
    .value__clear-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 1rem;
      font-size: 1.5rem;
      color: var(--bms-placeholder-color, #c1c1c1);
      border: none;
      background: none;
      cursor: pointer;

      &:hover,
      &:focus {
        color: inherit;
      }
    }
    .clear-btn__icon {
      transform: rotate(45deg);
    }
    .value__arrow {
      
      transition: transform 250ms ease-out;


      & .arrow__icon {
        transform: translate(0%, 0%);
        width: 10px;
        height: 10px;
        border-width: 6px 6px 0 6px;
        border-color: var(--bms-border-color, #000000) transparent transparent
          transparent;
        border-style: solid;
      }
    }
  }
  .base-multiselect__select {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* display: none; */
    z-index: 100;
    background-color: var(--bms-select-bg, #ffffff);
    border: var(--bms-select-border-width, 1px) solid
      var(--bms-select-border-color, #dddde3);

    .options__list {
      max-height: 25vh;
      overflow-y: auto;
      scroll-behavior: smooth;
    }

    /* -------- style for scrollbar -------- */
    .options__list::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    .options__list:hover::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    .options__list::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .options__list::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: grey;
    }
    /* -------- ----- --- --------- -------- */
    .options__item {
      &:hover {
        background-color: var(--bms-option-hover, rgba(33, 150, 243, 0.1));
      }
      &:focus {
        /* background-color: var(--bms-option-focus, rgba(33, 150, 243, 0.3)); */
      }

      .item__input {
      }
      .item__input:checked ~ .item__label {
        background-color: var(--bms-option-focus, rgba(33, 150, 243, 0.3));
      }
      .item__label {
        display: block;
        padding: 0.5rem;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .disabled {
    background-color: #e2e8f0;
    border-color: #e2e8f0;
    cursor: default;

    &:hover {
       border-color: #e2e8f0;
    }
    .value__arrow .arrow__icon {
      border-block-color: initial;
    }
  }
}
.selection__options {
  padding: 5px 5px;
  background-color: #f9f9f9;
}
.search-input {
  width: 100%;
  border: 1px solid #dfdcdc;
  border-radius: 7px; 
  margin-top: 5px; 
  margin-bottom: 10px;
  padding: 5px 5px;
}
</style>
