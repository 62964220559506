<template>
  <div class="spinner-container">
    <div class="spinner-overlay" :style="overlayStyle"></div>
    <div class="spinner">
      <PulseLoader
          :size="size"
          :margin="margin"
          :radius="radius"/>
    </div>
  </div>
</template>
<script>
import { PulseLoader } from "vue3-spinner";

export default {
  name: "spinner",
  props: {
    overlayStyle:{ type:String, required: false, default: ''},
  },
  components: {
    PulseLoader
  },
  data() {
    return {
      isLoading: true,
      spinnerShow: true,
      size: "30px",
      margin: "10px",
      radius: "100%",

    };
  },

  computed: {
  },

  methods: {

  }
};
</script>
<style lang="scss">
.spinner-container {
  position: relative;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
