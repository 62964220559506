// import { sendRequest } from "@/helpers";
export default {
  async uploadFile(data) {
    const baseURL = process.env.VUE_APP_API_URL
    const uploadUrl = process.env.VUE_APP_CDN_URL ? `${baseURL}/upload-file/` : `${baseURL}/upload_file/`;
    let response = await fetch(uploadUrl, {
      method: "post",
      mode: "cors",
      headers: {        
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: data,
    }).catch((error) => {
      console.error("Error:", error);
    });
    if (response.ok) {
      return await response.json();
    }
    else return null;     
    // return await sendRequest(`/upload_file/`, "post", data);
  },
};
