<template>
<!-- :style="[areaWidth && areaHeight ? 'width: ' + areaWidth + 'px; height:' + areaHeight + 'px;' : '']" -->
  <base-modal :showCloseBtn="true" v-model="showing" :showOwerlay="true">
    <div class="d-flex w-100 platform-scroller"
      style="position: relative; height: calc(100vh - 50px);"
      
      ref="scrollableArea"
      id="zoomCont"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @mouseleave="handleMouseUp"
      @touchstart="touchmove"
      @touchmove="touchmove"
      @touchend="handleEnd"
    >
      <div id="zoomEl" class="d-flex justify-content-center align-items-center w-100 h-100" v-if="file_url[currIndex]">
        <img :src="file_url[currIndex]" style="max-width: 100%; max-height: 100%; pointer-events: none; height: fit-content;"/>

        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="100%" preserveAspectRatio="none" style="object-fit: cover;">
          <image :href="file_url[currIndex]" width="100%" style="object-fit: cover;"/>
          <g>
            <polygon points="50 60, 150 75, 155 96, 0 60" fill="#044B94" fill-opacity="0.4" stroke-width="5"/>
            <text x="50" y="50" font-family="Verdana" font-size="35" fill="blue">Hello</text>
          </g>
        </svg> -->
      </div>

      <div style="position: absolute; top: 50%; right: 5px;" v-if="currIndex < (file_url.length - 1)">
        <button class="btn btn-secondary" @click="currIndex = currIndex + 1;">
          <i class="icon-left" style="color: rgb(24, 24, 24);"></i>
        </button>
      </div>
      <div style="position: absolute; top: 50%; left: 5px;" v-if="currIndex > 0">
        <button class="btn btn-secondary" @click="currIndex = currIndex - 1;">
          <i class="icon-right" style="color: rgb(0, 0, 0);"></i>
        </button>
      </div>
    </div>

    <div v-show="false">
      <input
          class="range"
          v-model="scale"
          type="range"
          :min="scaleMin"
          :max="scaleMax"
          :step="step"
      />
      <base-button
          style="margin-left: 5px"
          size="s"
          @click="zoomRes()"
          :disabled="scale === 1"
      >
        Reset
      </base-button>
    </div>
  </base-modal>
      
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "ImageZoomer",
  props: {
    file_url: {
      required: true,
      type: Array,
      default: () => []
    },
    index: {type: Number, default: 0},
    show: {
      type: Boolean,
    },
  },
  emits: ["closing"],
  inject: [],
  components: {
    BaseButton,
    BaseModal,
  },
  data() {
    return {
      ind: 0,
      showing: false,
      currIndex: this.index,

      imgH: null,
      imgW: null,

      scale: 1,
      step: 0.1,
      scaleMin: 0.99,
      scaleMax: 5,
      areaWidth: 0,
      areaHeight: 0,
      isMouseDown: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,

      //touch
      lastPinch: 0,
      stepTouch: 0.01,
    };
  },
  watch: {
    scale(newVal) {
      let el = document.getElementById("zoomEl");
      if (el) {
        el.style.transform =
            el.style.WebkitTransform =
                el.style.MsTransform =
                    "scale(" + newVal + ")";
        el.style.transformOrigin = "0 0";
      }

    },
    show(newVal){
      if(newVal){
        this.showing = true;
      }
    },
    showing(newVal){
      if(!newVal){
        this.$emit('closing', false)
      } else {
        this.zoomPhoto();
      }
    },
    index(newval){
      this.currIndex = newval;
    }
  },
  computed: {
  },
  methods: {
    zoomPhoto() {
      this.scale = 1;

      this.$nextTick(() => {
        this.calculateAreaSize();
        this.detectCtrlWheel();
        window.addEventListener("resize", this.calculateAreaSize);
      });
    },
    detectCtrlWheel() {
      let el = document.getElementById("zoomCont");
      let this2 = this;

      el.addEventListener("mousewheel", function (e) {
        if (e.ctrlKey) {
          var delta = e.deltaY || e.detail || e.wheelDelta;

          if (delta > 0) {
            if (this2.scale > this2.scaleMin)
              this2.scale = this2.scale - this2.step;
          } else {
            if (this2.scale < this2.scaleMax)
              this2.scale = this2.scale + this2.step;
          }

          e.preventDefault();
        }
      });
    },
    calculateAreaSize() {
      const scrollableArea = this.$refs.scrollableArea;
      this.areaWidth = scrollableArea.offsetWidth;
      this.areaHeight = scrollableArea.offsetHeight;
    },
    zoomRes() {
      this.scale = 1;
    },
    handleMouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.scrollLeft = this.$refs.scrollableArea.scrollLeft;
      this.scrollTop = this.$refs.scrollableArea.scrollTop;
      this.$refs.scrollableArea.style.cursor = "grabbing";
    },
    handleMouseMove(e) {
      if (!this.isMouseDown) return;
      const x = e.clientX;
      const y = e.clientY;
      const deltaX = x - this.startX;
      const deltaY = y - this.startY;
      this.$refs.scrollableArea.scrollLeft = this.scrollLeft - deltaX;
      this.$refs.scrollableArea.scrollTop = this.scrollTop - deltaY;
    },
    handleMouseUp() {
      this.isMouseDown = false;
      this.$refs.scrollableArea.style.cursor = "grab";

      this.lastPinch = 0;
    },
    touchmove(event){
      if (event.touches && event.touches.length > 1) {
        this.zoomPinch(event);
        return;
      }
    },
    zoomPinch(event) {

      let curDiff = Math.abs(event.touches[0].clientX - event.touches[1].clientX);

      if (this.lastPinch) {
          event.deltaY = this.lastPinch - curDiff;
          
          let delta = event.deltaY;
          if (delta > 0) {
              if (this.scale > this.scaleMin)
              this.scale = this.scale - this.stepTouch;
          } else {
              if (this.scale < this.scaleMax)
              this.scale = this.scale + this.stepTouch;
          }

          event.preventDefault();
      }
      this.lastPinch = curDiff;
    },
    handleEnd(event) {
        if (event.button && event.button !== 0) {
            return;
        }
        this.lastPinch = 0;
    },
  },
  created() {
  },
  mounted() {
    if(this.file_url[this.currIndex]){
      let grid = new Image()
      grid.src = this.file_url[this.currIndex];
      grid.onload = () => {
        this.imgW = grid.width;
        this.imgH = grid.height;
      };
    }
    
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateAreaSize);
  },
};
</script>
<style scoped>
.w-50 {
  width: 50%;
}

.analizator-container {
  min-height: 600px;
}

.btn-secondary {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #303030;
}

.icon-left:before {
  content: "\02C3";
}

.icon-right:before {
  content: "\02C2";
}

.platform-scroller {
  z-index: 1;
  flex-grow: 1;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  margin-right: 0px;
  margin-bottom: 0px;
}

.tabs-content {
  overflow: auto;
}
</style>
