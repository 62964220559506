import { sendRequest } from "@/helpers";

export default {
  async getSettings() {
    return await sendRequest(`/api/dashboard/get_dashboard_filters`, "get", "");
  },

  async getMainDashboard(params) {
    return await sendRequest(`/api/dashboard/get_dashboard_data?${params}`, "get", "");
  },

  async postMainDashboard(entity, params) {
    return await sendRequest(`/api/dashboard/get_dashboard_data?${entity}`, "post", params);
  },

  async postReplanishment(entity, params){
    return await sendRequest(`/api/dashboard/get_replenishment_data?${entity}`, "post", params)
  },

  async getPlanogrammaData(params) {
    return await sendRequest(`/api/dashboard/get_planogram_data`, "get", params);
  },
  async postPlanogrammaData(params) {
    return await sendRequest(`/api/dashboard/get_planogram_data`, "post", params);
  },

  async postWrongReceipts(entity,params = null){
    return await sendRequest(`/api/dashboard/get_error_reports_data?${entity}`, "post", params);
  },

};
