<template>
  <div class="v-card v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated" id="basecard_container">
    <div class="v-card-item py-2 px-6" v-if="hasHeaderSlot">
      <div class="v-card-item__content">
        <div class="d-sm-flex align-center justify-space-between">
          <div class="v-card-title text-h5 w-100"><slot name="header"></slot></div>
        </div>
      </div>
    </div>
    <hr
      class="v-divider v-theme--BLUE_THEME"
      aria-orientation="horizontal"
      role="separator"
    />
    <div class="v-card-text w-100 h-100" style="padding: 5px !important;" v-if="hasDefaultSlot" >
      <slot></slot>
    </div>
    <div v-if="hasContentSlot">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseCard",
  props: {

  },
  inject: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasContentSlot() {
      return !!this.$slots.content;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    }
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.error {
  background-color: brown;
}
.v-card{
  box-shadow: none !important;
}
</style>
